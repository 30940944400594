var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', [_c('v-list-item-group', {
    attrs: {
      "mandatory": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "dense": "",
      "to": "/member/point/charge"
    }
  }, [_c('v-list-item-title', [_vm._v("포인트 충전")])], 1), _c('v-list-item', {
    attrs: {
      "dense": "",
      "to": "/member/point/points"
    }
  }, [_c('v-list-item-title', [_vm._v("포인트 내역")])], 1), _c('v-list-item', {
    attrs: {
      "dense": "",
      "to": "/member/point/point-gift"
    }
  }, [_c('v-list-item-title', [_vm._v("포인트 선물하기")])], 1), _c('v-list-item', {
    attrs: {
      "dense": "",
      "to": "/member/point/gifts"
    }
  }, [_c('v-list-item-title', [_vm._v("포인트 선물내역")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }