<template>
    <default-template>
        <v-layout class="fill-height justify-center align-start background-content">
            <v-responsive width="100%" max-width="1200" class="py-2 py-lg-16" :class="{ 'px-4': $vuetify.breakpoint.mobile }">
                <v-layout>
                    <v-flex v-if="!$vuetify.breakpoint.mobile" shrink>
                        <v-card width="260" flat color="primary" class="white--text" elevation="0" tile>
                            <v-card-title>
                                <span style="font-size: 22px;">포인트관리</span>
                            </v-card-title>
                            
                            <mypage-points />

                        </v-card>
                    </v-flex>
                    <v-flex shrink :class="{ 'pl-4': !$vuetify.breakpoint.mobile }" style="flex-grow: 1 !important;">
                        <slot />
                    </v-flex>
                </v-layout>
            </v-responsive>
        </v-layout>
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
import MypagePoints from "@/components/client/mypage/mypage-points.vue";
export default {
    components: {
        DefaultTemplate,
        MypagePoints
    },
    created(){
        if(this.logoff) this.$router.push(`/login`);
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        logoff(){
            return !this.$store.state.accessToken;
        }
    },
}
</script>
